<template>
  <div>
    <div></div>
    <div>
      <div class="font-24 mt-10 ml-4">Edit Category</div>
      <div class="justify-center" align="center">
        <!-- Top Bar Chart -->
        <v-card class="mt-2 mx-4">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form
                        name="details"
                        class="formPart text-left"
                        @submit.prevent="submitForm(id)"
                      >
                        <!-- Name -->
                        <div>
                          <div class="form-group col">
                            <label for="name">Name:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              placeholder="Enter Name"
                              v-model.trim="details.name"
                              @input="setTouch('name')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.name.required && $v.details.name.$error"
                            >
                              name is required
                            </div>
                          </div>
                        </div>

                        <!-- <div>
                          <div class="form-group col">
                            <label for="mobile"> Select Image:</label> <br />

                            <v-file-input
                              prepend-icon=""
                              type="file"
                              class="form-control"
                              id="designer-image"
                              v-model.trim="details.image"
                              @change="handleImage"
                              accept="image/jpeg, image/png"
                            ></v-file-input>
                          </div>
                          <div
                            class="preview-image border p-2 mt-3 ml-3"
                            v-if="details.image"
                          >
                            <v-img
                              :src="details.image"
                              height="100%"
                              contain
                            ></v-img>
                          </div>
                        </div> -->

                        <div class="d-flex">
                          <div class="form-group col-6">
                            <div>
                              <label for="image">Mobile Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (W 163 &#10005; 163 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.imageMobile"
                                placeholder=" Select Image"
                                accept="image/jpeg, image/png"
                                @change="handleMobileImage"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="
                                  !$v.details.imageMobile.required && $v.details.imageMobile.$error
                                "
                              >
                                Mobile Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                              <v-img :src="mobileUrl" height="230" width="100%" contain></v-img>
                            </div>
                          </div>

                          <div class="form-group col-6">
                            <div>
                              <label for="image"> Desktop Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (288 W &#10005; 288 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.image"
                                placeholder=" Select Image"
                                @change="handleDesktopImage"
                                accept="image/jpeg, image/png"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="!$v.details.image.required && $v.details.image.$error"
                              >
                                Desktop Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                              <v-img :src="desktopUrl" height="230" contain></v-img>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="email">Sequence:</label>

                            <input
                              type="text"
                              id="sequence"
                              class="form-control"
                              name="sequence"
                              placeholder="sequence"
                              v-model.trim="details.sequence"
                              @input="setTouch('sequence')"
                              min="0"
                              max="10"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.sequence.required && $v.details.sequence.$error"
                            >
                              sequence is required
                            </div>
                          </div>
                        </div>
                        <div class="ml-4 mt-2">
                          <label for="gender">Gender</label>
                          <div class="d-flex">
                            <div>
                              <input
                                type="radio"
                                v-model="details.gender"
                                value="Male"
                                class="mr-1"
                                @change="updateCategory()"
                              />
                              <label>Male</label>
                            </div>
                            <div class="ml-4">
                              <input
                                type="radio"
                                v-model="details.gender"
                                value="Female"
                                class="mr-1"
                                @change="updateCategory()"
                              />
                              <label>Female</label>
                            </div>
                            <div
                              class="validation"
                              v-if="!$v.details.gender.required && $v.details.gender.$error"
                            >
                              Gender is required
                            </div>
                          </div>
                        </div>
                        <div class="form-group mx-4 pt-4" v-if="details.assignedCategories">
                          <label for="email"> Assign Categories:</label>

                          <v-select
                            :items="categories"
                            v-model="details.assignedCategories"
                            append-icon="▼"
                            multiple
                            item-text=""
                            class="form-control"
                            prepend-icon=""
                          >
                          </v-select>

                          <!-- <div
                            class="validation"
                            v-if="
                              !$v.details.assignedCategories.required &&
                              $v.details.assignedCategories.$error
                            "
                          >
                            Assigned Categories is required
                          </div> -->
                        </div>

                        <div>
                          <div class="form-group col">
                            <label for="Link">Link:</label>
                            <input
                              type="text"
                              id="link"
                              class="form-control"
                              name="link"
                              placeholder="link"
                              v-model.trim="details.link"
                              @input="setTouch('link')"
                            />
                            <!-- <div
                              class="validation"
                              v-if="!$v.details.link.required && $v.details.link.$error"
                            >
                              Link is required
                            </div> -->
                          </div>
                        </div>
                        <!-- button -->
                        <div class="btnPart text-center my-4">
                          <button type="submit" class="btn btn-success">Save</button>
                          <p class="typo__p" v-if="submitStatus === 'OK'">
                            Thanks for your submission!
                          </p>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'">
                            Please fill the form correctly.
                          </p>
                          <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import homeService from '../../services/homeService';
import productService from '../../services/productService';
import imageUploadService from '../../services/imageUploadService';

export default {
  components: {},
  data() {
    return {
      details: {
        name: '',
        image: '',
        imageMobile: '',
        sequence: 0,
        gender: '',
        assignedCategories: [],
        link: '',
      },
      submitStatus: false,
      id: '',
      mobileUrl: null,
      desktopUrl: null,
      categories: [],
      Women: [],
      Men: [],
    };
  },
  validations: {
    details: {
      name: {
        required,
      },
      image: {
        required,
      },
      imageMobile: {
        required,
      },
      sequence: {
        required,
      },
      gender: {
        required,
      },
    },
  },
  async created() {
    this.id = this.$route.params.id;
    if (this.id) {
      await this.getAllCategoryItems();
      await this.getOneCategory(this.id);
    }
  },
  methods: {
    setTouch(fields) {
      if (fields === 'name') {
        this.$v.details.name.$touch();
      } else if (fields === 'image') {
        this.$v.details.image.$touch();
      } else if (fields === 'imageMobile') {
        this.$v.details.image.$touch();
      } else if (fields === 'sequence') {
        this.$v.details.sequence.$touch();
      } else if (fields === 'gender') {
        this.$v.details.gender.$touch();
      }
    },
    // this is use for save button
    async submitForm(id) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });

        this.submitStatus = 'false';
      } else {
        console.log(id, 'id');
        this.submitStatus = 'true';
        const result = await homeService.updateCategory(id, this.details);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewCategories');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
        console.log(result);
      }
    },
    async getOneCategory(id) {
      console.log(id, 'id');
      const result = await homeService.getOneCategory(id);
      this.details.assignedCategories = await result.data.assignedCategories;
      this.details.name = result.data.name;
      this.details.image = result.data.image;
      this.details.imageMobile = result.data.imageMobile;
      this.details.sequence = result.data.sequence;
      this.details.gender = result.data.gender;
      this.details.link = result.data.link;
      this.desktopUrl = result.data.image;
      this.mobileUrl = result.data.imageMobile;
      await this.updateCategory();
      console.log(result, 'result', this.details);
    },

    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('/viewCategories');
      });
    },
    async handleDesktopImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.image = result.data.meta.location;
          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl = await URL.createObjectURL(this.details.imageMobile);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.imageMobile = result.data.meta.location;

          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async getAllCategoryItems() {
      this.categories = [];
      const result = await productService.getAllCategoryItems();

      this.Women = await result.data.female.map((item) => {
        return item.name;
      });

      this.Men = await result.data.male.map((item) => {
        return item.name;
      });
    },
    updateCategory() {
      console.log('here also');
      if (this.details.gender == 'Female') {
        this.categories = this.Women;
      } else if (this.details.gender == 'Male') {
        this.categories = this.Men;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 40%;
  height: 350px;
}
</style>
